<template>
  <div class="DeveloperUsersList">
    <ul>
      <li v-for="(user, index) in developerUsers" :key="index">
        <user-item :user="user" :userindex="index" />
      </li>
    </ul>
    <infinite-loading
      ref="infiniteLoading"
      spinner="spiral"
      @infinite="infiniteHandler"
    >
      <span slot="no-results">All developer users loaded.</span>
      <span slot="no-more">All developer users loaded.</span>
    </infinite-loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import UserItem from './DeveloperUserItem';

export default {
  name: 'DeveloperUsersList',
  components: {
    UserItem,
    InfiniteLoading,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['developerUsers']),
  },
  methods: {
    infiniteHandler($state) {
      this.$store.dispatch('getDeveloperUsers').then(({ developerUsers }) => {
        if (developerUsers) {
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/developer/developer-users/DeveloperUsersList.scss';
</style>
